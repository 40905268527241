export const LENGTH_UNIT = 'mm';

export const MOUSE_KEY = {
  LeftButton: 1,
  RightButton: 2,
  MiddleButton: 4,
};

export const TOOLS = {
  Length: 'Length',
  Delete: 'Delete',
  Pan: 'Pan',
  Zoom: 'Zoom',
  Rotate: 'Rotate',
  Slice: 'Slice',
  FastScroll: 'FastScroll',
  Wwwc: 'Wwwc',
  RectangleRoi: 'RectangleRoi',
  Nidus: 'nidus',
  EllipticalRoi: 'EllipticalRoi',
  Angle: 'Angle',
  Cross: 'Cross',
};

export const COMMON_KEYS = {
  minus: 189, // -
  plus: 187, // +
  prev: 38, // up
  next: 40, // down
  resize: 82, // r
  prevGroup: 65, // A
  nextGroup: 68, // D
  notCorrected: 67, // C
  lesionSeg: 70, // F
  metaInfo: 73, // I
  prePage: 74, // J
  nextPage: 76, // L
  left: 37, // left
  right: 39, // right
  space: 32, // Space（空格键)
  resetR: 82, // R
  resetW: 85, // U
  backSpace: 8, // backspace(mac delete)
};

export const ALL_TOOLS = {
  [MOUSE_KEY.LeftButton]: [
    TOOLS.FastScroll,
    TOOLS.Wwwc,
    TOOLS.Pan,
    TOOLS.Zoom,
    TOOLS.Rotate,
    TOOLS.Length,
    TOOLS.RectangleRoi,
    TOOLS.EllipticalRoi,
    TOOLS.Angle,
    TOOLS.Cross,
  ],
  [MOUSE_KEY.RightButton]: [TOOLS.Wwwc],
  [MOUSE_KEY.MiddleButton]: [TOOLS.FastScroll],
};

export const DEFAULT_VIEWPORT = {
  scale: 1,
  initScale: 1,
  voi: {
    windowCenter: 50,
    windowWidth: 100,
  },
  translation: {
    x: 0,
    y: 0,
  },
  rotation: 0,
};

export const ORIENTATION = {
  sagittal: '0,1,0,0,0,-1', // 矢状位
  coronal: '1,0,0,0,0,-1', // 冠状面
  axial: '1,0,0,0,1,0', // 轴状位
};
