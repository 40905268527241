import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import routes from '@/routes.json';

const { PRODUCTPATH } = process.env;
const Router: React.FC = () => (
  <BrowserRouter>
    <Routes>
      {Object.entries(routes).map(([path, component]) => {
        const Comp = React.lazy(() => import(`${component}`));
        return (
          <Route
            key={path}
            path={`${PRODUCTPATH}${path}`}
            element={
              <React.Suspense fallback={<div />}>
                <Comp />
              </React.Suspense>
            }
          />
        );
      })}
    </Routes>
  </BrowserRouter>
);

export default Router;
