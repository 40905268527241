import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import lungReucer from '@/stores/lungSlice';
import toolSliceReucer from '@/stores/toolSlice';
import whiteSliceReucer from '@/stores/whiteSlice';
import searchParamsReucer from '@/stores/searchParams';
// 数据持久化
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['white'],
};

const rootReducer = combineReducers({
  lung: lungReucer,
  tool: toolSliceReucer,
  white: whiteSliceReucer,
  searchParams: searchParamsReucer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk];
const store = createStore(persistedReducer, applyMiddleware(...middlewares));
const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export { store, persistor };
