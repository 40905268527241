import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICase } from '@/pages/Lung/types';
interface IWhiteSlice {
  caseInfo: ICase;
}

const defaultState: IWhiteSlice = {
  caseInfo: {
    caseState: '',
    caseUuid: '',
    imageCount: '',
    key: '',
    number: 0,
    patientId: '',
    patientName: '',
    seriesDescription: '',
    studyTime: '',
  },
};

const whiteSlice = createSlice({
  name: 'white',
  initialState: defaultState,
  reducers: {
    setCaseInfo(state, action: PayloadAction<ICase>) {
      state.caseInfo = action.payload;
    },
  },
});

const { actions, reducer } = whiteSlice;

export const { setCaseInfo } = actions;

export default reducer;
