import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

declare const process;

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'zh',
    backend: {
      loadPath: `${process.env.PRODUCTPATH}/locales/{{lng}}.json`,
    },
    detection: {
      order: ['cookie'],
      lookupCookie: 'lng',
    },
    react: {
      useSuspense: true,
    },
  });

export default i18next;
