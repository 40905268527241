import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILesionSlice, ISegmentSlice, ISeries, ISerieWwAndWl, IPage, IView, ICase } from '@/pages/Lung/types';
import { SEGMENTGROUP } from '@/components/StlViewer/constants';
import { IPosition } from '@/pages/ViewPort/types';
interface ILungSlice {
  userInfo: ISeries | null;
  currentPage: IPage;
  segmentList: ISegmentSlice[];
  lesionList: ILesionSlice[];
  curAxialSlice: number;
  curCoronalnSlice: number;
  curSagittalSlice: number;
  pointAxial: {
    x: number;
    y: number;
    scale: number;
    multipleX: number;
    multipleY: number;
  };
  pointCoronal: {
    x: number;
    y: number;
    scale: number;
    multipleX: number;
    multipleY: number;
  };
  pointSagittal: {
    x: number;
    y: number;
    scale: number;
    multipleX: number;
    multipleY: number;
  };
  pointShow: boolean;
  seriesWwAndWlDifference: ISerieWwAndWl;
  seriesCoronalWwAndWlDifference: ISerieWwAndWl;
  seriesSagittalWwAndWlDifference: ISerieWwAndWl;
  renderFinished: boolean;
  isMobiled: boolean;
  showVr: boolean;
  currentView: IView;
  caseInfo: ICase;
  addVesselTool: boolean;
  activePosition: IPosition;
  dicomMaskRect: { height: number };
  curSelectLeisonId: string;
  dicomSize: {
    width: number;
    height: number;
  };
}

export const defaultState: ILungSlice = {
  userInfo: null,
  currentPage: 'imageExtraction',
  segmentList: SEGMENTGROUP,
  lesionList: [],
  curAxialSlice: 1,
  curCoronalnSlice: 1,
  curSagittalSlice: 1,
  pointAxial: { x: 512 / 2 - 1, y: 512 / 2 - 1, scale: 1, multipleX: 0.5, multipleY: 0.5 },
  pointCoronal: { x: 512 / 2 - 1, y: 512 / 2 - 1, scale: 1, multipleX: 0.5, multipleY: 0.5 },
  pointSagittal: { x: 512 / 2 - 1, y: 512 / 2 - 1, scale: 1, multipleX: 0.5, multipleY: 0.5 },
  pointShow: false,
  seriesWwAndWlDifference: {
    ww: 0,
    wl: 0,
  },
  seriesCoronalWwAndWlDifference: {
    ww: 0,
    wl: 0,
  },
  seriesSagittalWwAndWlDifference: {
    ww: 0,
    wl: 0,
  },
  renderFinished: false,
  isMobiled: false,
  showVr: false,
  currentView: 'vr',
  caseInfo: {
    caseState: '',
    caseUuid: '',
    imageCount: '',
    key: '',
    number: 0,
    patientId: '',
    patientName: '',
    seriesDescription: '',
    studyTime: '',
  },
  addVesselTool: false,
  activePosition: 'axial',
  dicomMaskRect: { height: 0 },
  curSelectLeisonId: '',
  dicomSize: {
    width: 512,
    height: 512,
  },
};

const lungSlice = createSlice({
  name: 'series',
  initialState: defaultState,
  reducers: {
    setCurrentView: (state, action: PayloadAction<IView>) => {
      state.currentView = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<ISeries>) => {
      state.userInfo = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<IPage>) => {
      state.currentPage = action.payload;
    },
    setSegmentList(state, action: PayloadAction<ISegmentSlice[]>) {
      state.segmentList = action.payload;
    },
    setLesionList(state, action: PayloadAction<ILesionSlice[]>) {
      state.lesionList = action.payload;
    },
    setCurAxialSlice(state, action: PayloadAction<number>) {
      state.curAxialSlice = action.payload;
    },
    setCurCoronalnSlice(state, action: PayloadAction<number>) {
      state.curCoronalnSlice = action.payload;
    },
    setCurSagittalSlice(state, action: PayloadAction<number>) {
      state.curSagittalSlice = action.payload;
    },
    setPointAxial(
      state,
      action: PayloadAction<{ x: number; y: number; scale: number; multipleX: number; multipleY: number }>
    ) {
      state.pointAxial = action.payload;
    },
    setPointCoronal(
      state,
      action: PayloadAction<{ x: number; y: number; scale: number; multipleX: number; multipleY: number }>
    ) {
      state.pointCoronal = action.payload;
    },
    setPointSagittal(
      state,
      action: PayloadAction<{ x: number; y: number; scale: number; multipleX: number; multipleY: number }>
    ) {
      state.pointSagittal = action.payload;
    },
    setPointShow(state, action: PayloadAction<boolean>) {
      state.pointShow = action.payload;
    },
    updateSeriesWwAndWlDifference: (state, action: PayloadAction<{ value: ISerieWwAndWl; type: IPosition }>) => {
      const {
        value: { ww, wl },
        type,
      } = action.payload;
      switch (type) {
        case 'axial':
          state.seriesWwAndWlDifference = { ww, wl };
          break;
        case 'coronal':
          state.seriesCoronalWwAndWlDifference = { ww, wl };
          break;
        default:
          state.seriesSagittalWwAndWlDifference = { ww, wl };
          break;
      }
    },
    resetSeriesWwAndWl: (state) => {
      state.seriesWwAndWlDifference = { ww: 0, wl: 0 };
      state.seriesCoronalWwAndWlDifference = { ww: 0, wl: 0 };
      state.seriesSagittalWwAndWlDifference = { ww: 0, wl: 0 };
    },
    setRenderFinished(state, action: PayloadAction<boolean>) {
      state.renderFinished = action.payload;
    },
    setMobiled(state, action: PayloadAction<boolean>) {
      state.isMobiled = action.payload;
    },
    setShowVr(state, action: PayloadAction<boolean>) {
      state.showVr = action.payload;
    },
    setCaseInfo(state, action: PayloadAction<ICase>) {
      state.caseInfo = action.payload;
    },
    setAddVesselTool(state, action: PayloadAction<boolean>) {
      state.addVesselTool = action.payload;
    },
    setActivePosition(state, action: PayloadAction<IPosition>) {
      state.activePosition = action.payload;
    },
    setDicomMaskRect(state, action: PayloadAction<{ height: number }>) {
      state.dicomMaskRect = action.payload;
    },
    setCurSelectLeisonId(state, action: PayloadAction<string>) {
      state.curSelectLeisonId = action.payload;
    },
    setDicomSize(state, action: PayloadAction<{ width: number; height: number }>) {
      state.dicomSize = action.payload;
    },
    clearLungSlice(state, action: PayloadAction<ILungSlice>) {
      state.activePosition = action.payload.activePosition;
      state.addVesselTool = action.payload.addVesselTool;
      state.caseInfo = action.payload.caseInfo;
      state.curAxialSlice = action.payload.curAxialSlice;
      state.curCoronalnSlice = action.payload.curCoronalnSlice;
      state.curSagittalSlice = action.payload.curSagittalSlice;
      state.curSelectLeisonId = action.payload.curSelectLeisonId;
      state.currentView = action.payload.currentView;
      state.dicomMaskRect = action.payload.dicomMaskRect;
      state.pointAxial = action.payload.pointAxial;
      state.pointCoronal = action.payload.pointCoronal;
      state.pointSagittal = action.payload.pointSagittal;
      state.pointShow = action.payload.pointShow;
      state.segmentList = action.payload.segmentList;
      state.seriesWwAndWlDifference = action.payload.seriesWwAndWlDifference;
      state.showVr = action.payload.showVr;
      state.userInfo = action.payload.userInfo;
      state.renderFinished = action.payload.renderFinished;
      state.lesionList = action.payload.lesionList;
    },
  },
});

const { actions, reducer } = lungSlice;

export const {
  setCurrentPage,
  setLesionList,
  setCurAxialSlice,
  setCurCoronalnSlice,
  setCurSagittalSlice,
  setPointAxial,
  setPointCoronal,
  setPointSagittal,
  setPointShow,
  setUserInfo,
  setSegmentList,
  updateSeriesWwAndWlDifference,
  resetSeriesWwAndWl,
  setRenderFinished,
  setMobiled,
  setShowVr,
  setCurrentView,
  setCaseInfo,
  setAddVesselTool,
  setActivePosition,
  setDicomMaskRect,
  setCurSelectLeisonId,
  clearLungSlice,
  setDicomSize,
} = actions;

export default reducer;
