import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TOOLS } from '@/pages/Lung/constants';
import { IdrawLesionInfoSlice } from '@/pages/ViewPort/types';

interface ITool {
  selectedTool: string;
  drawLesionInfo: IdrawLesionInfoSlice;
  showAnotationMask: boolean;
  showCoronalAnotationMask: boolean;
  showSagittalAnotationMask: boolean;
  drawingLesion: boolean;
  hiddenLine: boolean;
}

const defaultState: ITool = {
  selectedTool: TOOLS.Slice,
  drawLesionInfo: {
    translateCenter: {
      x: 0,
      y: 0,
    },
    center: {
      x: -9999,
      y: -9999,
    },
    radius: 0,
    length: 0,
    seriesIndex: 1,
  },
  showAnotationMask: true,
  showCoronalAnotationMask: true,
  showSagittalAnotationMask: true,
  drawingLesion: false,
  hiddenLine: true,
};

const toolSlice = createSlice({
  name: 'tool',
  initialState: defaultState,
  reducers: {
    setDrawingLesion: (state, action: PayloadAction<boolean>) => {
      state.drawingLesion = action.payload;
    },
    setTool: (state, action: PayloadAction<string>) => {
      state.selectedTool = action.payload;
    },
    setDrawLesionInfo: (state, action: PayloadAction<IdrawLesionInfoSlice>) => {
      state.drawLesionInfo = action.payload;
    },
    setShowAnotationMask: (state, action: PayloadAction<boolean>) => {
      state.showAnotationMask = action.payload;
    },
    setShowCoronalAnotationMask: (state, action: PayloadAction<boolean>) => {
      state.showCoronalAnotationMask = action.payload;
    },
    setShowSagittalAnotationMask: (state, action: PayloadAction<boolean>) => {
      state.showSagittalAnotationMask = action.payload;
    },
    setHiddenLine: (state, action: PayloadAction<boolean>) => {
      state.hiddenLine = action.payload;
    },
  },
});

const { actions, reducer } = toolSlice;
export const {
  setTool,
  setDrawingLesion,
  setDrawLesionInfo,
  setShowAnotationMask,
  setHiddenLine,
  setShowCoronalAnotationMask,
  setShowSagittalAnotationMask,
} = actions;
export default reducer;
