import 'moment/locale/zh-cn';
import '@sk/common/polyfill';

import { enableMapSet } from 'immer';
import moment from 'moment';
import * as React from 'react';
import { Provider } from 'react-redux';
import { disableMousewheelEvent, getLng } from '@sk/common';
import { ConfigProvider } from '@sk/components';
import { enUS, zhCN } from '@sk/components/lib/ConfigProvider';

import Router from '@/Router';
import { store, persistor } from '@/stores';
import { PersistGate } from 'redux-persist/integration/react';
import i18n from '@/utils/i18n';

import '@/style/app.scss';

disableMousewheelEvent();
enableMapSet();

const App = () => {
  const [lng, setLng] = React.useState<string>('');

  React.useEffect(() => {
    i18n.on('initialized', () => {
      const locale = getLng();
      const isEn = locale?.includes('en');

      if (isEn) {
        moment.locale('en');
      } else {
        moment.locale('zh-cn');
      }

      setLng(locale);
    });

    i18n.on('languageChanged', (locale) => {
      const isEn = locale?.includes('en');

      if (isEn) {
        moment.locale('en');
      } else {
        moment.locale('zh-cn');
      }

      setLng(locale);
    });

    return () => {
      i18n.off('initialized');
      i18n.off('languageChanged');
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider locale={lng?.includes('en') ? enUS : zhCN}>{lng && <Router />}</ConfigProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
