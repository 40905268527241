import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface ImageParams {
  params: {
    page: number;
    patientName: string;
    studyStartDt: string;
    studyEndDt: string;
    value: number;
  };
  timeParams: {
    isTimer: boolean;
  };
  followParams: {
    patientIdOrName: string;
    followNumber: number;
    recentFollowTimestampStart: string;
    recentFollowTimestampEnd: string;
    pageNum: number;
    pageSize: number;
  };
  lesionParams: {
    orderBy?: string;
    caseUuid: string;
    nidusType: any[];
    longDiameter: any[];
  };
  ipWhiteParams: {
    ipOrDescription: string;
    pageNum: number;
    pageSize: number;
  };
}

const defaultState: ImageParams = {
  params: {
    page: 1,
    patientName: '',
    studyStartDt: '',
    studyEndDt: '',
    value: 99,
  },
  timeParams: {
    isTimer: false,
  },
  followParams: {
    patientIdOrName: '',
    followNumber: -1,
    recentFollowTimestampStart: '',
    recentFollowTimestampEnd: '',
    pageNum: 1,
    pageSize: 50,
  },
  lesionParams: {
    orderBy: '',
    caseUuid: '',
    nidusType: [],
    longDiameter: [],
  },
  ipWhiteParams: {
    ipOrDescription: '',
    pageNum: 1,
    pageSize: 50,
  },
};

const searchParams = createSlice({
  name: 'series',
  initialState: defaultState,
  reducers: {
    setParams(
      state,
      action: PayloadAction<{
        page: number;
        patientName: string;
        studyStartDt: string;
        studyEndDt: string;
        value: number;
      }>
    ) {
      state.params = action.payload;
    },
    setTimerParams(
      state,
      action: PayloadAction<{
        isTimer: boolean;
      }>
    ) {
      state.timeParams = action.payload;
    },
    setFollowParams(
      state,
      action: PayloadAction<{
        patientIdOrName: string;
        followNumber: number;
        recentFollowTimestampStart: string;
        recentFollowTimestampEnd: string;
        pageNum: number;
        pageSize: number;
      }>
    ) {
      state.followParams = action.payload;
    },
    setLesionParams(
      state,
      action: PayloadAction<{
        orderBy?: string;
        caseUuid: string;
        nidusType: any[];
        longDiameter: any[];
      }>
    ) {
      state.lesionParams = action.payload;
    },
    setIpWhiteParams(
      state,
      action: PayloadAction<{
        ipOrDescription: string;
        pageNum: number;
        pageSize: number;
      }>
    ) {
      state.ipWhiteParams = action.payload;
    },
  },
});

const { actions, reducer } = searchParams;

export const { setParams, setTimerParams, setFollowParams, setLesionParams, setIpWhiteParams } = actions;

export default reducer;
