// color字典
export const MODEL_COLOR = {
  lesion: '#000',
  cutEdgeBall: '#ffffff',
  bronchial: '#d2cece',
  artery: '#f60304',
  vein: '#2255fe',
  ribs: '#ffffff',
  skin: '#fdf2f2',
  lung: '#FFB6C1',
  left_top: '#FFD666',
  'left_S1+2': '#FFB6C1',
  left_S3: '#f79dd5', // '#C71585',
  left_S4: '#ffc8e1', // '#8B008B',
  left_S5: '#ded3f5', // '#9370DB',
  left_bottom: '#36aaff',
  left_S6: '#E6E6FA',
  'left_S7+8': '#fff1c9', // '#d9aa21',
  left_S9: '#8ddefd', // '#0000CD',
  left_S10: '#c9defb', // '#66a2f1',
  right_top: '#aa54ff',
  right_S1: '#FFB6C1', // '#87CEFA',
  right_S2: '#f79dd5', // '#008B8B',
  right_S3: '#ffc8e1', // '#00FA9A',
  right_middle: '#3C9AE8',
  right_bottom: '#faa9ff',
  right_S4: '#ded3f5', // '#00BFFF',
  right_S5: '#E6E6FA', // '#008000',
  right_S6: '#fff1c9', // '#F5F5DC',
  right_S7: '#8ddefd', // '#FFFF00',
  right_S8: '#c9defb', // '#FFD700',
  right_S9: '#87CEFA', // '#FF8C00',
  right_S10: '#ffb5b7', // '#F08080',
};
// 肺段分割
export const SEGMENTGROUP = [
  {
    label: '结节',
    name: 'lesion',
    color: MODEL_COLOR['lesion'],
    path: '',
    type: 'lesion',
    opacity: 1,
  },
  {
    label: '切缘',
    name: 'cutEdgeBall',
    color: MODEL_COLOR['cutEdgeBall'],
    path: '',
    type: 'cutEdgeBall',
    opacity: 0.5,
  },
  {
    label: '支气管',
    name: 'bronchial',
    color: MODEL_COLOR['bronchial'],
    path: '',
    type: 'bronchial',
    opacity: 1,
    period: [
      {
        label: 'B_L',
        name: 'bronchial_left',
        color: MODEL_COLOR['bronchial'],
        path: '',
        opacity: 1,
      },
      {
        label: 'B_M',
        name: 'bronchial_middle',
        color: MODEL_COLOR['bronchial'],
        path: '',
        opacity: 1,
      },
      {
        label: 'B_R',
        name: 'bronchial_right',
        color: MODEL_COLOR['bronchial'],
        path: '',
        opacity: 1,
      },
    ],
  },
  {
    label: '肺动脉',
    name: 'artery',
    color: MODEL_COLOR['artery'],
    path: '',
    type: 'artery',
    opacity: 1,
    period: [
      {
        label: 'A_L',
        name: 'artery_left',
        color: MODEL_COLOR['artery'],
        path: '',
        opacity: 1,
      },
      {
        label: 'A_M',
        name: 'artery_middle',
        color: MODEL_COLOR['artery'],
        path: '',
        opacity: 1,
      },
      {
        label: 'A_R',
        name: 'artery_right',
        color: MODEL_COLOR['artery'],
        path: '',
        opacity: 1,
      },
    ],
  },
  {
    label: '肺静脉',
    name: 'vein',
    color: MODEL_COLOR['vein'],
    path: '',
    type: 'vein',
    opacity: 1,
    period: [
      {
        label: 'V_L',
        name: 'vein_left',
        color: MODEL_COLOR['vein'],
        path: '',
        opacity: 1,
      },
      {
        label: 'V_M',
        name: 'vein_middle',
        color: MODEL_COLOR['vein'],
        path: '',
        opacity: 1,
      },
      {
        label: 'V_R',
        name: 'vein_right',
        color: MODEL_COLOR['vein'],
        path: '',
        opacity: 1,
      },
    ],
  },
  {
    label: '肋骨',
    name: 'ribs',
    color: MODEL_COLOR['ribs'],
    path: '',
    type: 'ribs',
    opacity: 0.5,
  },
  {
    label: '皮肤',
    name: 'skin',
    color: MODEL_COLOR['skin'],
    path: '',
    type: 'skin',
    opacity: 0.5,
  },
  {
    label: '肺',
    name: 'lung',
    color: MODEL_COLOR['lung'],
    path: '',
    type: 'lung',
    opacity: 0.5,
  },
  {
    label: '左上叶',
    name: 'left_top',
    color: MODEL_COLOR['left_top'],
    path: '',
    opacity: 0.5,
    type: 'left',
    period: [
      /**
       * 亚段
       */
      {
        label: 'S1+2',
        name: 'left_S1+2',
        color: MODEL_COLOR['left_S1+2'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S3',
        name: 'left_S3',
        color: MODEL_COLOR['left_S3'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S4',
        name: 'left_S4',
        color: MODEL_COLOR['left_S4'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S5',
        name: 'left_S5',
        color: MODEL_COLOR['left_S5'],
        path: '',
        opacity: 0.5,
      },
      /**
       * 支气管细分
       */
      {
        label: 'S1+2a',
        name: 'left_S1+2a',
        color: MODEL_COLOR['left_S1+2'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S1+2b',
        name: 'left_S1+2b',
        color: MODEL_COLOR['left_S1+2'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S1+2c',
        name: 'left_S1+2c',
        color: MODEL_COLOR['left_S1+2'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S3a',
        name: 'left_S3a',
        color: MODEL_COLOR['left_S3'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S3b',
        name: 'left_S3b',
        color: MODEL_COLOR['left_S3'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S3c',
        name: 'left_S3c',
        color: MODEL_COLOR['left_S3'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S4a',
        name: 'left_S4a',
        color: MODEL_COLOR['left_S4'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S4b',
        name: 'left_S4b',
        color: MODEL_COLOR['left_S4'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S5a',
        name: 'left_S5a',
        color: MODEL_COLOR['left_S5'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S5b',
        name: 'left_S5b',
        color: MODEL_COLOR['left_S5'],
        path: '',
        opacity: 0.5,
      },
    ],
  },
  {
    label: '左下叶',
    name: 'left_bottom',
    color: MODEL_COLOR['left_bottom'],
    path: '',
    opacity: 0.5,
    type: 'left',
    period: [
      /**
       * 亚段
       */
      {
        label: 'S6',
        name: 'left_S6',
        color: MODEL_COLOR['left_S6'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S7+8',
        name: 'left_S7+8',
        color: MODEL_COLOR['left_S7+8'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S9',
        name: 'left_S9',
        color: MODEL_COLOR['left_S9'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S10',
        name: 'left_S10',
        color: MODEL_COLOR['left_S10'],
        path: '',
        opacity: 0.5,
      },
      /**
       * 支气管细分
       */
      {
        label: 'S6a',
        name: 'left_S6a',
        color: MODEL_COLOR['left_S6'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S6b',
        name: 'left_S6b',
        color: MODEL_COLOR['left_S6'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S6c',
        name: 'left_S6c',
        color: MODEL_COLOR['left_S6'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S7+8a',
        name: 'left_S7+8a',
        color: MODEL_COLOR['left_S7+8'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S7+8b',
        name: 'left_S7+8b',
        color: MODEL_COLOR['left_S7+8'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S9a',
        name: 'left_S9a',
        color: MODEL_COLOR['left_S9'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S9b',
        name: 'left_S9b',
        color: MODEL_COLOR['left_S9'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S10a',
        name: 'left_S10a',
        color: MODEL_COLOR['left_S10'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S10b',
        name: 'left_S10b',
        color: MODEL_COLOR['left_S10'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S10c',
        name: 'left_S10c',
        color: MODEL_COLOR['left_S10'],
        path: '',
        opacity: 0.5,
      },
    ],
  },
  {
    label: '右上叶',
    name: 'right_top',
    color: MODEL_COLOR['right_top'],
    path: '',
    opacity: 0.5,
    type: 'right',
    period: [
      /**
       * 亚段
       */
      {
        label: 'S1',
        name: 'right_S1',
        color: MODEL_COLOR['right_S1'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S2',
        name: 'right_S2',
        color: MODEL_COLOR['right_S2'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S3',
        name: 'right_S3',
        color: MODEL_COLOR['right_S3'],
        path: '',
        opacity: 0.5,
      },
      /**
       * 支气管细分
       */
      {
        label: 'S1a',
        name: 'right_S1a',
        color: MODEL_COLOR['right_S1'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S1b',
        name: 'right_S1b',
        color: MODEL_COLOR['right_S1'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S2a',
        name: 'right_S2a',
        color: MODEL_COLOR['right_S2'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S2b',
        name: 'right_S2b',
        color: MODEL_COLOR['right_S2'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S3a',
        name: 'right_S3a',
        color: MODEL_COLOR['right_S3'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S3b',
        name: 'right_S3b',
        color: MODEL_COLOR['right_S3'],
        path: '',
        opacity: 0.5,
      },
    ],
  },
  {
    label: '右中叶',
    name: 'right_middle',
    color: MODEL_COLOR['right_middle'],
    path: '',
    opacity: 0.5,
    type: 'right',
    period: [
      /**
       * 亚段
       */
      {
        label: 'S4',
        name: 'right_S4',
        color: MODEL_COLOR['right_S4'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S5',
        name: 'right_S5',
        color: MODEL_COLOR['right_S5'],
        path: '',
        opacity: 0.5,
      },
      /**
       * 支气管细分
       */
      {
        label: 'S4a',
        name: 'right_S4a',
        color: MODEL_COLOR['right_S4'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S4b',
        name: 'right_S4b',
        color: MODEL_COLOR['right_S4'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S5a',
        name: 'right_S5a',
        color: MODEL_COLOR['right_S5'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S5b',
        name: 'right_S5b',
        color: MODEL_COLOR['right_S5'],
        path: '',
        opacity: 0.5,
      },
    ],
  },
  {
    label: '右下叶',
    name: 'right_bottom',
    color: MODEL_COLOR['right_bottom'],
    path: '',
    opacity: 0.5,
    type: 'right',
    period: [
      /**
       * 亚段
       */
      {
        label: 'S6',
        name: 'right_S6',
        color: MODEL_COLOR['right_S6'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S7',
        name: 'right_S7',
        color: MODEL_COLOR['right_S7'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S8',
        name: 'right_S8',
        color: MODEL_COLOR['right_S8'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S9',
        name: 'right_S9',
        color: MODEL_COLOR['right_S9'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S10',
        name: 'right_S10',
        color: MODEL_COLOR['right_S10'],
        path: '',
        opacity: 0.5,
      },
      /**
       * 支气管细分
       */
      {
        label: 'S6a',
        name: 'right_S6a',
        color: MODEL_COLOR['right_S6'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S6b',
        name: 'right_S6b',
        color: MODEL_COLOR['right_S6'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S6c',
        name: 'right_S6c',
        color: MODEL_COLOR['right_S6'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S7a',
        name: 'right_S7a',
        color: MODEL_COLOR['right_S7'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S7b',
        name: 'right_S7b',
        color: MODEL_COLOR['right_S7'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S8a',
        name: 'right_S8a',
        color: MODEL_COLOR['right_S8'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S8b',
        name: 'right_S8b',
        color: MODEL_COLOR['right_S8'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S9a',
        name: 'right_S9a',
        color: MODEL_COLOR['right_S9'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S9b',
        name: 'right_S9b',
        color: MODEL_COLOR['right_S9'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S10a',
        name: 'right_S10a',
        color: MODEL_COLOR['right_S10'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S10b',
        name: 'right_S10b',
        color: MODEL_COLOR['right_S10'],
        path: '',
        opacity: 0.5,
      },
      {
        label: 'S10c',
        name: 'right_S10c',
        color: MODEL_COLOR['right_S10'],
        path: '',
        opacity: 0.5,
      },
    ],
  },
];
// 切缘范围
export const MARKS = {
  0: '0',
  8: '8',
  20: '20',
  50: '50mm',
};
// 快捷选项
export const SHORTCUTOPTIONS = [
  {
    label: 'ROI相交肺段视角',
    name: 'spotlight',
    path: 'spotlight',
    opacity: 0.5,
  },
  {
    label: '相交肺段流域视角',
    name: 'cutview',
    path: 'cutview',
    opacity: 0.5,
  },
  {
    label: '段门视角',
    name: 'portal',
    path: 'portal',
    opacity: 0.5,
  },
  {
    label: '左肺',
    name: 'leftlung',
    path: 'spotlight',
    opacity: 0.5,
  },
  {
    label: '右肺',
    name: 'rightlung',
    path: 'spotlight',
    opacity: 0.5,
  },
  {
    label: '全肺',
    name: 'alllung',
    path: 'spotlight',
    opacity: 1,
  },
];
// 段落实体化
export const ENTITYABLE = ['vein', 'lesion', 'artery', 'bronchial'];
// 分割段落实体化
export const PERIODENTITYABLE = [
  'vein_left',
  'vein_middle',
  'vein_right',
  'artery_left',
  'artery_middle',
  'artery_right',
  'bronchial_left',
  'bronchial_middle',
  'bronchial_right',
];
